/*
  font-faces generated using https://transfonter.org/
  Avenir - with "Strip Hinting" setting
  Graphik - "Keep Existing"
*/

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/avenir/Avenir-Light.woff2') format('woff2'),
      url('../../fonts/avenir/Avenir-Light.woff') format('woff'),
      url('../../fonts/avenir/Avenir-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/avenir/Avenir-Roman.woff2') format('woff2'),
      url('../../fonts/avenir/Avenir-Roman.woff') format('woff'),
      url('../../fonts/avenir/Avenir-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/avenir/Avenir-Medium.woff2') format('woff2'),
      url('../../fonts/avenir/Avenir-Medium.woff') format('woff'),
      url('../../fonts/avenir/Avenir-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/avenir/Avenir-Heavy.woff2') format('woff2'),
      url('../../fonts/avenir/Avenir-Heavy.woff') format('woff'),
      url('../../fonts/avenir/Avenir-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('../../fonts/avenir/Avenir-Black.woff2') format('woff2'),
      url('../../fonts/avenir/Avenir-Black.woff') format('woff'),
      url('../../fonts/avenir/Avenir-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('../../fonts/avenir/Avenir-Book.woff2') format('woff2'),
      url('../../fonts/avenir/Avenir-Book.woff') format('woff'),
      url('../../fonts/avenir/Avenir-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
