/*
  We are using only 400, 500, 600 font weights
  so i am disabling other until they will be required
*/

/*@font-face {
    font-family: 'Graphik LC';
    src: url('../../fonts/graphik/GraphikLC-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LC';
    src: url('../../fonts/graphik/GraphikLC-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LC';
    src: url('../../fonts/graphik/GraphikLC-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}*/

@font-face {
    font-family: 'Graphik LC';
    /*src: url('../../fonts/graphik/GraphikLC-Regular-1.ttf') format('truetype');*/
    src: url('../../fonts/graphik/GraphikLC-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LC';
    /*src: url('../../fonts/graphik/GraphikLC-Medium.ttf') format('truetype');*/
    src: url('../../fonts/graphik/GraphikLC-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LC';
    /*src: url('../../fonts/graphik/GraphikLC-Semibold.ttf') format('truetype');*/
    src: url('../../fonts/graphik/GraphikLC-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/*@font-face {
    font-family: 'Graphik LC';
    src: url('../../fonts/graphik/GraphikLC-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LC';
    src: url('../../fonts/graphik/GraphikLC-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik LC Super';
    src: url('../../fonts/graphik/GraphikLC-Super.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}*/
