@import 'normalize.css';
@import './fonts/avenir.pcss';
@import './fonts/graphik.pcss';
@import './toast.pcss';


html,
body {
  box-sizing: border-box;
  height: 100%;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 1.4;

  scrollbar-width: thin;
}

.light-theme {
  scrollbar-color: #F4F5FA transparent;
}

#root {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Scrollbar styling - Dark Theme */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  border: 0 #fff; /* donno for what is that */
  border-radius: 50px;
  background: var(--pageScrollBackground);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--pageScrollBackground);
}

::-webkit-scrollbar-thumb:active {
  background: var(--pageScrollBackground);
}

::-webkit-scrollbar-track {
  border: 0 #fff;
  border-radius: 0;
  background: var(--scroll);
}

::-webkit-scrollbar-track:hover {
  background: var(--scroll);
}

::-webkit-scrollbar-track:active {
  background: var(--scroll);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

#portal {
  position: relative;
  z-index: -1000;
}
