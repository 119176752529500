
.Toastify__toast-container--top-right {
  top: 74px;
  right: 0;
  padding: 0;
}

.Toastify__toast--warning,
.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--info {
  background: var(--inputBackground);
  border-left: 7px solid;
  border-radius: 4px;
  color: var(--textColor);
  font-family: Graphik LC;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  padding: 16px 16px 16px 20px;
}

.Toastify__toast--success {
  border-color: var(--success);
}

.Toastify__toast--warning {
  border-color: var(--alert);
}

.Toastify__toast--error {
  border-color: var(--error);
}

.Toastify__toast--info {
  border-color: var(--info);
}

.Toastify__toast-container {
  min-width: 350px;
}

.Toastify__close-button {
  color: var(--textColor);
}
